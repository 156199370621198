import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import './style.scss'

class SiteContainedPage extends React.Component {
  render() {
    const { site, data, isIndex } = this.props
    const title = get(data, 'frontmatter.title')
    const path = get(data, 'frontmatter.path')
    const html = get(data, 'html')

    return (
      <div className="contained-page container">
        <div className="page-header">
          <Link to={path}>
            <h1>{title}</h1>
          </Link>
        </div>
        <div
          className="page-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    )
  }
}

export default SiteContainedPage
